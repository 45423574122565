import { createResponsiveConfig, ResponsiveImageSize } from '@loveholidays/design-system';
import {
  AllSystemCSSProperties,
  ResponsiveStyleValue,
  SystemStyleObject,
} from '@styled-system/css';
import React, { forwardRef } from 'react';

import { ClassNameProps } from '@ComponentProps';

type SearchCardVariantStyling = {
  headerAreaConfig: SystemStyleObject;
  infoAreaConfig: SystemStyleObject;
  subActionConfig: SystemStyleObject;
  priceAreaConfig: SystemStyleObject;
  bodyAreaConfig: SystemStyleObject;
  actionAreaConfig: SystemStyleObject;

  // Mobile width is dynamic. The mobile width here is not to be used to size the content on screen.
  // Instead, it is used as an approximation from which we work out the best image size to download.
  imageWidths: [number, number, number];
  imageHeights: ResponsiveImageSize;
};

type SearchCardVariantGrid = {
  gridTemplate?: ResponsiveStyleValue<AllSystemCSSProperties['gridTemplateAreas']>;
};

const commonStyling: SystemStyleObject = {
  // Absolutely positioned children within the area should be relative to the area.
  position: 'relative',
};

const defaultStyling: SearchCardVariantStyling = {
  headerAreaConfig: {
    padding: 's',
  },
  infoAreaConfig: {
    borderStyle: 'solid none none',
    flexDirection: ['column', 'row'],
    alignItems: [null, 'center'],
    paddingY: ['xs', 's'],
    paddingRight: ['s', 'xs'],
    paddingLeft: 's',
  },
  subActionConfig: {
    margin: ['3xs', 'xs'],
  },
  priceAreaConfig: {
    borderStyle: ['solid none none', 'none'],
    paddingBottom: [null, '2xs'],
    flexDirection: ['row', 'column'],
    alignItems: ['center', 'flex-end'],
    paddingTop: ['xs', 0],
    paddingX: ['s', 'xs'],
  },
  bodyAreaConfig: {
    borderStyle: 'none',
    paddingLeft: 's',
    paddingBottom: 'xs',
    paddingTop: [0, '2xs'],
    gap: '3xs',
    minWidth: 0,
  },
  actionAreaConfig: {
    padding: 's',
    paddingTop: ['3xs', 0, null],
    paddingBottom: [null, 'xs', '2xs'],
    paddingRight: [null, 'xs'],
  },
  imageWidths: [327, 300, 400],
  imageHeights: [200, 200, 298],
};

const cmaStyling: SearchCardVariantStyling = {
  headerAreaConfig: {
    padding: 'xs',
  },
  infoAreaConfig: {
    borderStyle: ['solid none none', 'none'],
    flexDirection: 'column',
    alignItems: 'start',
    padding: 'xs',
  },
  subActionConfig: {
    borderStyle: ['none', 'none none none solid'],
    paddingX: ['3xs', 'xs'],
    paddingTop: ['3xs', 0],
    marginTop: [0, 'xs'],
  },
  priceAreaConfig: {
    borderStyle: ['none', 'none none none solid'],
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: '2xs',
    paddingTop: '2xs',
    paddingX: 'xs',
  },
  bodyAreaConfig: {
    borderStyle: ['solid none none', 'none none none solid'],
    wordBreak: 'break-all',
    paddingTop: 'xs',
    paddingX: 'xs',
    gap: '4xs',
  },
  actionAreaConfig: {
    padding: 'xs',
    paddingTop: [0, null],
    paddingBottom: ['xs', '2xs'],
    paddingRight: [null, 'xs'],
  },
  imageWidths: [200, 250, 400],
  imageHeights: [200, 200, 298],
};

export type SearchCardVariants = 'default' | 'defaultWithSubAction' | 'cma' | 'cmaWithSubAction';
type Config = Record<SearchCardVariants, SearchCardVariantStyling & SearchCardVariantGrid>;

export const searchCardConfig: ReturnType<typeof createResponsiveConfig<Config>> =
  createResponsiveConfig<Config>({
    default: {
      ...defaultStyling,
      gridTemplate: [
        `
        "image" "headers" "body" "info" "price" "action"
      `,
        `
        "image  headers headers"
        "image  body    price"
        "info   info    info"
        "action action  action"
      `,
        `
        "image headers headers headers"
        "image body    body    price"
        "image info    info    info"
        "image action  action  action"
      `,
      ],
    },
    defaultWithSubAction: {
      ...defaultStyling,
      gridTemplate: [
        `
        "image" "headers" "body" "info" "price" "action"
      `,
        `
        "image  headers headers subAction"
        "image  body    price   price"
        "info   info    info    info"
        "action action  action  action"
      `,
        `
        "image headers headers headers subAction"
        "image body    price   price   price"
        "image info    info    info    info"
        "image action  action  action  action"
      `,
      ],
    },
    cma: {
      ...cmaStyling,
      gridTemplate: [
        `
        "image   image"
        "headers headers"
        "info    info"
        "body    body"
        "price    price"
        "action  action"
      `,
        `
        "image  headers headers"
        "image  info    body"
        "image  info    price"
        "image  action  action"
      `,
        `
        "image headers headers headers"
        "image headers headers body"
        "image info    info    body"
        "image info    info    price"
        "image action  action  action"
      `,
      ],
    },
    cmaWithSubAction: {
      ...cmaStyling,
      gridTemplate: [
        `
        "image   image"
        "headers headers"
        "info    info"
        "body    body"
        "price    price"
        "action  action"
      `,
        `
        "image headers headers subAction"
        "image headers headers body"
        "image info    info    body"
        "image info    info    price"
        "image action  action  action"
      `,
        `
        "image headers headers subAction subAction"
        "image headers headers body   body"
        "image info    info    body   body"
        "image info    info    price   price"
        "image action  action  action action"
      `,
      ],
    },
  });

type SearchCardProps = ClassNameProps & {
  variant: SearchCardVariants;
  imageArea: React.ReactNode;
  headerArea: React.ReactNode;
  bodyArea: React.ReactNode;
  infoArea: React.ReactNode;
  actionArea: React.ReactNode;
  priceArea: React.ReactNode;
  subActionArea?: React.ReactNode;
};

export const SearchCard = forwardRef<HTMLElement, SearchCardProps>((cardProps, ref) => {
  const {
    'data-id': dataId,
    className,
    variant,
    imageArea,
    headerArea,
    bodyArea,
    infoArea,
    actionArea,
    priceArea,
    subActionArea,
  } = cardProps;

  const {
    gridTemplate,
    imageWidths,
    headerAreaConfig,
    imageHeights,
    infoAreaConfig,
    bodyAreaConfig,
    priceAreaConfig,
    subActionConfig,
    actionAreaConfig,
  } = searchCardConfig.get(variant);

  return (
    <section
      data-id={dataId}
      ref={ref}
      className={className}
      sx={{
        display: 'grid',
        gridTemplateAreas: gridTemplate,
        gridTemplateColumns: [
          null,
          `${imageWidths[1]}px 1fr auto min-content`,
          `${imageWidths[2]}px 1fr auto auto min-content`,
        ],
        backgroundColor: 'backgroundWhite',
        borderRadius: '12',
        borderColor: 'strokeLightneutral',
        borderStyle: 'solid',
        borderWidth: 'outlinedStrokeWeight',
        overflow: 'hidden',
        position: 'relative',
        '& > div': {
          borderWidth: 'outlinedStrokeWeight',
          borderColor: 'strokeLightneutral',
        },
      }}
    >
      <div
        sx={{
          ...commonStyling,
          gridArea: 'image',
          minHeight: imageHeights,
        }}
      >
        {imageArea}
      </div>
      <div
        sx={{
          ...commonStyling,
          ...headerAreaConfig,
          gridArea: 'headers',
          paddingBottom: ['xs', 0],
          paddingTop: [null, 'xs'],
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '3xs',
        }}
      >
        {headerArea}
      </div>
      <div
        sx={{
          ...commonStyling,
          ...bodyAreaConfig,
          gridArea: 'body',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: [null, 'flex-end'],
          flexWrap: 'wrap',
        }}
      >
        {bodyArea}
      </div>
      <div
        sx={{
          ...commonStyling,
          ...infoAreaConfig,
          gridArea: 'info',
          display: 'flex',
          flexWrap: [null, 'wrap'],
          gap: '2xs',
        }}
      >
        {infoArea}
      </div>
      <div
        sx={{
          ...commonStyling,
          ...actionAreaConfig,
          gridArea: 'action',
          display: ['grid', null],
          gridAutoColumns: '1fr',
          gridAutoFlow: 'column',
          alignItems: 'end',
          alignContent: 'stretch',
          gap: ['2xs', '3xs'],
        }}
      >
        {actionArea}
      </div>
      <div
        sx={{
          ...commonStyling,
          ...priceAreaConfig,
          gridArea: 'price',
          display: 'flex',
          gap: ['2xs', '3xs'],
          justifyContent: 'flex-end',
        }}
      >
        {priceArea}
      </div>
      {subActionArea && (
        <div
          sx={{
            ...commonStyling,
            ...subActionConfig,
            position: ['absolute', 'relative'],
            gridArea: [null, 'subAction'],
            right: 0,
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {subActionArea}
        </div>
      )}
    </section>
  );
});
